export const DEFAULT_COLUMNS = [{Header: "data", accessor: "data"}];
export const DEFAULT_DATA = [];

export const WINS_API_PATH_METRIC_QUERY = "/query";
export const WINS_API_PATH_ONBOARD = "/onboard";

export const METRIC_OPTIONS = [
    { label: "Top 100 Clicked Asins", value: "top-100-clicked-asins" },
    { label: "Top 100 Viewed Page Asins", value: "top-100-viewed-page-asins" },
    { label: "Color Trend", value: "clicks-per-color" },
    { label: "Brand Trend", value: "clicks-per-brand" },
    { label: "Product Type Trend", value: "clicks-per-product-type"},
];

/**
 * Currently only IN marketplace (key - eu:44571) has cohort data available
 * Add new keys as cohort datasets become available for more regions/marketplaces
 */
export const REGION_MARKETPLACE_WITH_COHORT_METRICS_AVAILABLE = new Set(["eu:44571"]);
export const ALL_COHORT_OPTIONS = [
    { label: "All", value: "All" },
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female"},
];
export const DEFAULT_COHORT_OPTION = [{ label: "All", value: "All" }];

export const REGION_OPTIONS = [
    { label: "na", value: "na"},
    { label: "eu", value: "eu"},
    { label: "fe", value: "fe"},
    { label: "cn", value: "cn"}
];

export const QUERY_PAGE_PATH = "/widgetInstrumentation/Console";
export const QUERY_PAGE_ABSOLUTE_PATH = "/#/widgetInstrumentation/Console"
export const ONBOARD_PAGE_PATH = "/widgetInstrumentation/Onboard";