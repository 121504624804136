export interface ProjectConfig {
  readonly name: string;
  readonly description: string;
  readonly mainPage: string;
  readonly marketplaces: MarketPlace[];
  readonly wiki: string;
  readonly metricDashBoard: string;
  readonly status: ProjectStatus;
}

interface MarketPlace {
  readonly name: string;
  readonly marketplaceId: string;
}

export enum ProjectStatus {
  deployed = "Deployed",
  underDevelopment = "Under development",
  deprecated = "Deprecated",
}

const whatsInStyle: ProjectConfig = {
  name: "What's In Style",
  description: "Show curated Fashion Collections On Search Page",
  mainPage: "#/whatsInStyle",
  marketplaces: [
    {
      name: "IN",
      marketplaceId: "A21TJRUUN4KGV",
    },
  ],
  wiki:
    "https://w.amazon.com/bin/view/InternationalCountryExpansion/A2I/Softlines/Programs/What%27sInStyle/",
  metricDashBoard:
    "https://w.amazon.com/bin/view/InternationalCountryExpansion/A2I/Search/Programs/SoftlineSearch/Dashboards/Whatsinstyle",
  status: ProjectStatus.deployed,
};

const consoleForBeauty: ProjectConfig = {
  name: "Beauty Color Extraction Workflow Console",
  description: "A console to monitor and configure Color Extraction workflow of Beauty ASINs",
  mainPage: "#/beautyConsole",
  marketplaces: [
    {
      name: "Console",
      marketplaceId: "A21TJRUUN4KGV",
    },
  ],
  wiki:
      "https://w.amazon.com/bin/view/INCoreConsumablesAndPantry/CoreConsumables/Projects/INCCTColorExtractionWorkflow/",
  metricDashBoard:
      "https://w.amazon.com/bin/view/INCoreConsumablesAndPantry/CoreConsumables/Projects/INCCTColorExtractionWorkflow/Dashboard/",
  status: ProjectStatus.deployed,
};

const widgetInstrumentation: ProjectConfig = {
  name: "Widget Instrumentation",
  description: "Get insight of ASIN-level metrics for widget owner",
  mainPage: "#/widgetInstrumentation",
  marketplaces: [
    {
      name: "Console",
      marketplaceId: "A21TJRUUN4KGV",
    },
  ],
  wiki:
    "https://w.amazon.com/bin/view/InternationalCountryExpansion/A2I/Softlines/Projects/WidgetInstrumentation/",
  metricDashBoard:
    "https://w.amazon.com/bin/view/InternationalCountryExpansion/A2I/Softlines/Projects/WidgetInstrumentation/Dashboard/",
  status: ProjectStatus.underDevelopment,
};

export const wis = whatsInStyle;
export const projects: ProjectConfig[] = [whatsInStyle, consoleForBeauty, widgetInstrumentation];
