import { ClientConfigFactory } from "../configuration/ClientConfig";
import { newClient } from "@amzn/midway-identity-java-script-client";

export class MidwayIdentityClientFactory {
  public static async getClient() {
    const config = await ClientConfigFactory.getConfig();

    return newClient({
      httpEndpoint: {
        url: config.widgetInstrumentationRestApi.url,
      },
      httpSigning: {
        scheme: config.widgetInstrumentationRestApi.scheme,
        region: config.awsRegion,
        service: config.widgetInstrumentationRestApi.service,
      },
      midwayIdentity: {
        cognitoIdentityPoolId: config.widgetInstrumentationRestApi.cognitoIdentityPoolId,
      },
    });
  }
}