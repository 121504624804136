import {
    Alert,
    Box,
    Button,
    Modal,
    Select,
    SpaceBetween,
    StatusIndicator,
    Table,
    Toggle,
} from "@amzn/awsui-components-react-v3";
import {StatusCodes} from "http-status-codes";
import * as React from "react";
import "res/css/CewComponent.css";
import {
    CLOSE_ALERT_ARIA_LABEL,
    CLOSE_MODAL_ARIA_LABEL,
    FREQUENCY_DROPDOWN_EMPTY,
    FREQUENCY_DROPDOWN_LOADING_TEXT,
    FREQUENCY_DROPDOWN_PLACEHOLDER,
    FREQUENCY_LIST,
    LDAP_WITH_WRITE_PERMISSIONS,
    ModelStatus,
    SETTINGS_TAB_ALERT_HEADER,
} from "../../CewConstant";
import ChangeSettingRequest from "../../model/ChangeSettingRequest";
import {CewApi} from "../../ts/api/CewApi";
import {Status} from "../../ts/Status";
import {AppContext} from "../../ts/util/AppContext";

/**
 * Settings Tab under Color Extraction Console Page
 */
export class Settings extends React.Component<any, any> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);
        this.state = {
            currentSettings: [],
            frequencyOptions: FREQUENCY_LIST,
            isAuthorized: false,
            confirmPopUp: false,
            responseHidden: true,
            statusIndicatorType: "",
            changeResponseType: "",
            responseMessage: "",
        };
        this.getSettings = this.getSettings.bind(this);
        this.updateSelectedFrequency = this.updateSelectedFrequency.bind(this);
        this.updateSelectedStatus = this.updateSelectedStatus.bind(this);
        this.setConfirmPopUp = this.setConfirmPopUp.bind(this);
        this.changeSettings = this.changeSettings.bind(this);
        this.setStatusIndicatorType = this.setStatusIndicatorType.bind(this);
    }

    /**
     * Loads the current settings in the settings table
     */
    async componentDidMount() {
        await this.context.init();
        await this.getSettings();
    }

    /**
     * Sets the toggle buttons and frequency selection according to current settings
     */
    async setDefaultSelectionInTable() {
        for (let setting of this.state.currentSettings) {
            this.props.onStatusChange(
                setting.marketplace,
                ModelStatus[setting.status]
            );
            this.props.onfrequencyChange(setting.marketplace, {
                label: setting.frequency,
                value: setting.frequency,
            });
        }
    }

    /**
     * Sets the message and icon of status indicator based on success/error/loading event
     */
    async setStatusIndicatorType(state: Status, message: string) {
        switch (state) {
            case Status.Loading:
                this.setState({
                    responseHidden: false,
                    statusIndicatorType: "loading",
                    responseMessage: message,
                });
                break;
            case Status.Loaded:
                this.setState({
                    responseHidden: false,
                    statusIndicatorType: "success",
                    responseMessage: message,
                });
                break;
            case Status.LoadingFailed:
                this.setState({
                    responseHidden: false,
                    statusIndicatorType: "error",
                    responseMessage: message,
                });
                break;
        }
    }

    /**
     *  Calls fetchSettings API to get the settings of the model after UI is rendered.
     */
    async getSettings() {
        await this.setStatusIndicatorType(Status.Loading, "Fetching settings");
        let response;
        await CewApi
            .fetchSettings()
            .then((res) => {
                response = res;
            })
            .catch((error) => {
                console.error("Error while fetching settings : " + error);
                response = error.response;
            });

        switch (response.status) {
            case StatusCodes.OK:
                const settingsList = response.data.list;
                await this.setState({
                    currentSettings: settingsList,
                    isAuthorized: true,
                });
                await this.setStatusIndicatorType(Status.Loaded, "Success");
                await this.setDefaultSelectionInTable();
                break;
            case StatusCodes.UNAUTHORIZED:
                await this.setStatusIndicatorType(
                    Status.LoadingFailed,
                    "Unauthorized",
                );
                await this.setState({isAuthorized: false});
                break;
            case StatusCodes.BAD_REQUEST:
                await this.setStatusIndicatorType(
                    Status.LoadingFailed,
                    response.statusText
                );
                break;
            default:
                console.error("Received status code %i for GET /settings request", response.status);
                await this.setStatusIndicatorType(
                    Status.LoadingFailed,
                    response.statusText
                );
                break;
        }
    }

    /*
     *Updates the selection of frequency in drop down
     * @param event : event object containing details of selection
     * @param marketplace: marketplace for which frequency is changed by user
     */
    updateSelectedFrequency(event, marketplace) {
        this.props.onfrequencyChange(marketplace, event.detail.selectedOption);
    }

    /*
     * Changes the toggle button selection to ON/OFF
     * @param event : event object containing details of selection
     * @param marketplace: marketplace for which status is toggled
     */
    updateSelectedStatus(event, marketplace) {
        this.props.onStatusChange(
            marketplace,
            event.detail.checked ? ModelStatus.ENABLED : ModelStatus.DISABLED
        );
    }

    /*
     * Triggers the Modal to confirm the change when user clicks APPLY settings button
     * @param setTo : true or false depending on action
     */
    setConfirmPopUp(setTo: boolean) {
        this.setState({confirmPopUp: setTo});
    }

    /*
     * Whenever user clicks on the APPLY button,this function is triggered.
     */
    async changeSettings() {
        await this.setConfirmPopUp(false);
        await this.setStatusIndicatorType(Status.Loading, "Changing settings");
        const changeSettingRequestList = this.generateChangeSettingRequest();
        let response;
        await CewApi
            .changeSettings(changeSettingRequestList)
            .then((res) => {
                response = res;
            })
            .catch((error) => {
                console.error("Error while changing settings : " + error);
                response = error.response;
            });

        switch (response.status) {
            case StatusCodes.OK:
                await this.getSettings();
                await this.setStatusIndicatorType(Status.Loaded, "Settings applied successfully");
                break;
            case StatusCodes.MULTI_STATUS:
                await this.getSettings();
                let message = this.generateResponseForMultiStatus(response.data.list);
                await this.setStatusIndicatorType(Status.LoadingFailed, message);
                break;
            case StatusCodes.UNAUTHORIZED:
                await this.setState({isAuthorized: false});
                await this.setStatusIndicatorType(
                    Status.LoadingFailed,
                    "Unauthorized"
                );
                break;
            case StatusCodes.BAD_REQUEST:
                await this.setStatusIndicatorType(
                    Status.LoadingFailed,
                    response.statusText
                );
                break;
            default:
                console.error("Received status code %i for POST /settings request", response.status);
                await this.setStatusIndicatorType(
                    Status.LoadingFailed,
                    response.statusText
                );
                break;
        }
    }

    /*
     * Create a request object that represents change in settings done by user.This is passed in body to updateSettings API request
     */
    generateChangeSettingRequest(): Array<ChangeSettingRequest> {
        let changeSettingRequestList: Array<ChangeSettingRequest> = new Array<ChangeSettingRequest>();

        for (let currentSetting of this.state.currentSettings) {
            let statusChangeRequired: boolean = false;
            let frequencyChangeRequired: boolean = false;
            let changeFrequencyTo: string;
            let changeStatusTo: string;

            if (
                this.props.statusSelection.get(currentSetting.marketplace) !=
                currentSetting.status
            ) {
                statusChangeRequired = true;
                changeStatusTo = this.props.statusSelection.get(
                    currentSetting.marketplace
                );
            }

            if (
                this.props.statusSelection.get(currentSetting.marketplace) === ModelStatus.ENABLED &&
                this.props.frequencySelection.get(currentSetting.marketplace).value != currentSetting.frequency
            ) {
                frequencyChangeRequired = true;
                changeFrequencyTo = this.props.frequencySelection.get(
                    currentSetting.marketplace
                ).value;
            }

            if (statusChangeRequired || frequencyChangeRequired)
                changeSettingRequestList.push(
                    new ChangeSettingRequest(
                        currentSetting.marketplace,
                        frequencyChangeRequired,
                        changeFrequencyTo,
                        statusChangeRequired,
                        changeStatusTo
                    )
                );
        }
        return changeSettingRequestList;
    }

    /*
     * Generates a string of error message that indicates which marketplace's settings were not
     * changed due to backend error in case the response returned was multi status(207)
     */
    generateResponseForMultiStatus(responseList) {
        let errorList: string[] = [];
        for (let response of responseList) {
            if (
                (response.hasOwnProperty("frequencyChangeMessage") && response.frequencyChangeMessage === "error") ||
                (response.hasOwnProperty("statusChangeMessage") && response.statusChangeMessage === "error")
            ) {
                errorList.push(response.marketplace);
            }
        }

        if (errorList.length > 0) {
            return "Error while changing settings for " + errorList.toString();
        }
        return "";
    }

    /*
     * Defines how data is rendered in settings table
     */
    columnDefinitions = [
        {
            id: "marketplace",
            header: "Marketplace",
            cell: (item) => item.marketplace,
        },
        {
            id: "frequency",
            header: "Current Frequency",
            cell: (item) => item.frequency,
        },
        {
            id: "Change Button",
            header: "Change Frequency",
            cell: (item) => this.dropDownButton(item),
        },
        {
            id: "Status",
            header: "Current Status",
            cell: (item) => item.status,
        },
        {
            id: "Change Button",
            header: "Toggle to",
            cell: (item) => this.toggleButton(item),
        },
    ];

    /*
     * Returns a JSX element containing a drop down button for frequency selection
     */
    dropDownButton(item) {
        return (
            <div>
                <Box margin="xxs" padding="xxs">
                    <Select
                        className="select-drop-down"
                        placeholder={FREQUENCY_DROPDOWN_PLACEHOLDER}
                        loadingText={FREQUENCY_DROPDOWN_LOADING_TEXT}
                        empty={FREQUENCY_DROPDOWN_EMPTY}
                        options={this.state.frequencyOptions}
                        selectedOption={this.props.frequencySelection.get(item.marketplace)}
                        onChange={(e) => this.updateSelectedFrequency(e, item.marketplace)}
                        filteringType="none"
                        disabled={
                            this.props.statusSelection.get(item.marketplace) ===
                            ModelStatus.DISABLED
                        }
                    />
                </Box>
            </div>
        );
    }

    /*
     * Returns a JSX element containing a toggle button to toggle status to ON/OFF
     */
    toggleButton(item) {
        return (
            <div>
                <Box margin="xxs" padding="xxs">
                    <Toggle
                        onChange={(event) => {
                            this.updateSelectedStatus(event, item.marketplace);
                        }}
                        checked={
                            this.props.statusSelection.get(item.marketplace) ===
                            ModelStatus.ENABLED
                        }
                    >
                        {this.props.statusSelection.get(item.marketplace)}
                    </Toggle>
                </Box>
            </div>
        );
    }

    render() {
        return (
            <div>
                <SpaceBetween direction="vertical" size="xxs">
                    <div hidden={this.state.responseHidden}>
                            <StatusIndicator type={this.state.statusIndicatorType}>
                                {this.state.responseMessage}
                            </StatusIndicator>
                    </div>
                    {(!this.state.isAuthorized && this.state.statusIndicatorType != "loading") && (
                        <Alert
                            dismissAriaLabel={CLOSE_ALERT_ARIA_LABEL}
                            header={SETTINGS_TAB_ALERT_HEADER}
                        >
                            Access to this section is restricted. You need to be a part of {" "}
                            {LDAP_WITH_WRITE_PERMISSIONS} LDAP group to access this section.
                        </Alert>
                    )}
                    {this.state.isAuthorized && (
                        <div>
                            <SpaceBetween direction="vertical" size="s">
                                <div className="button-float-right">
                                    <Button
                                        variant="primary"
                                        onClick={() => this.setConfirmPopUp(true)}
                                    >
                                        Apply
                                    </Button>
                                </div>
                                <Table
                                    className="settings-table"
                                    columnDefinitions={this.columnDefinitions}
                                    items={this.state.currentSettings}
                                    wrapLines
                                />
                            </SpaceBetween>
                            <Modal
                                onDismiss={() => this.setConfirmPopUp(false)}
                                visible={this.state.confirmPopUp}
                                closeAriaLabel={CLOSE_MODAL_ARIA_LABEL}
                                size="medium"
                                footer={
                                    <Box float="right">
                                        <SpaceBetween direction="horizontal" size="xs">
                                            <Button variant="primary" onClick={this.changeSettings}>
                                                Confirm
                                            </Button>
                                        </SpaceBetween>
                                    </Box>
                                }
                                header="Are you sure?"
                            >
                                There is an IMR cost associated with each batch run.Please make
                                sure you are aware of the impact of this operation on the IMR
                                cost.
                            </Modal>
                        </div>
                    )}
                </SpaceBetween>
            </div>
        );
    }
}