import {Tabs} from "@amzn/awsui-components-react-v3";
import {Badge, Header} from "@amzn/awsui-components-react-v3/polaris";
import * as React from "react";
import {
    BATCH_RUN_TAB_LABEL,
    DEFAULT_DATE_RANGE_SELECTION_IN_DAYS,
    ModelStatus,
    SETTINGS_TAB_LABEL
} from "../CewConstant";
import {BatchRun} from "../component/color-extraction-console/BatchRunTab";
import {Settings} from "../component/color-extraction-console/SettingsTab";
import {AppContext} from "../ts/util/AppContext";
let dateFormat = require('dateformat');

interface FilterProps {
}

/**
 *  State to store filter parameters for both the child tabs
 */
interface FilterState {
    operator: string;
    toDate: string;
    fromDate: string;
    selectedMarketplaces: Array<object>; // to store filter state of multi select drop down on BatchRunHistory Tab
    selectedFrequencyMap: Map<string, object>;
    selectedStatusMap: Map<string, ModelStatus>;
}

export class CEWMainPage extends React.Component<FilterProps, FilterState> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(prop, state) {
        super(prop, state);
        this.state = {
            operator: "",
            selectedMarketplaces: [{label: "IN", value: "IN"}],
            toDate: dateFormat(new Date(), "isoDate"),
            fromDate: dateFormat(new Date(new Date().setDate(
                new Date().getDate() - DEFAULT_DATE_RANGE_SELECTION_IN_DAYS)), "isoDate"),
            selectedFrequencyMap: new Map<string, object>(),
            selectedStatusMap: new Map<string, ModelStatus>(),
        };
        this.updateFromDate = this.updateFromDate.bind(this);
        this.updateToDate = this.updateToDate.bind(this);
        this.updateSelectedMarketplaces = this.updateSelectedMarketplaces.bind(this);
        this.updateSelectedFrequency = this.updateSelectedFrequency.bind(this);
        this.updateSelectedStatus = this.updateSelectedStatus.bind(this);
    }

    /**
     * Fetch user alias
     */
    async componentDidMount() {
        await this.context.init();
        this.setState({operator: await this.context.getUserAmazonAlias()});
    }

    /**
     * Updates the from date state parameter when user changes date selection in BatchRun Tab
     * @param value : date entered by user
     */
    updateFromDate(value) {
        this.setState({fromDate: value});
    }

    /**
     * Updates the to date state parameter when user changes date selection in BatchRun Tab
     * @param value : date entered by user
     */
    updateToDate(value) {
        this.setState({toDate: value});
    }

    /**
     * Updates selectedMarketplaces filter when user updates selection in BatchRun Tab
     * @param selectedMarketplacesList : Object array representing marketplaces selected by user in multi select drop down
     */
    updateSelectedMarketplaces(selectedMarketplacesList) {
        this.setState({selectedMarketplaces: selectedMarketplacesList});
    }

    /**
     * Updates the frequency selected by the user in drop down in the settings tab table.
     * @param marketplace : marketplace for which frequency is changed
     * @param selectedOption : frequency to be set for the given marketplace
     */
    updateSelectedFrequency(marketplace, selectedOption) {
        let newMap = new Map(
            this.state.selectedFrequencyMap.set(marketplace, selectedOption)
        );
        this.setState({selectedFrequencyMap: newMap});
    }

    /**
     * Updates the status of the model for a particular in the settings tab table.
     * @param marketplace : marketplace for which status has be changed
     * @param selectedOption : string representing ON/OFF
     */
    updateSelectedStatus(marketplace, selectedOption) {
        let newMap = new Map(
            this.state.selectedStatusMap.set(marketplace, selectedOption)
        );
        this.setState({selectedStatusMap: newMap});
    }

    render() {
        return (
            <div>
                <Header
                    variant="h1"
                    actions={
                        <Badge color="grey">
                            <h2> Welcome : {this.state.operator}</h2>
                        </Badge>
                    }
                >
                    Beauty Color Extraction Workflow Console
                </Header>
                <Tabs
                    tabs={[
                        {
                            label: BATCH_RUN_TAB_LABEL,
                            id: "batchRunTab",
                            content: (
                                <BatchRun
                                    selection={this.state.selectedMarketplaces}
                                    toDate={this.state.toDate}
                                    fromDate={this.state.fromDate}
                                    onToDateChange={this.updateToDate}
                                    onFromDateChange={this.updateFromDate}
                                    onMarketplaceChange={this.updateSelectedMarketplaces}
                                />
                            ),
                        },
                        {
                            label: SETTINGS_TAB_LABEL,
                            id: "settingsTab",
                            content: (
                                <Settings
                                    frequencySelection={this.state.selectedFrequencyMap}
                                    statusSelection={this.state.selectedStatusMap}
                                    onfrequencyChange={this.updateSelectedFrequency}
                                    onStatusChange={this.updateSelectedStatus}
                                />
                            ),
                        },
                    ]}
                />
            </div>
        );
    }
}