import * as React from "react";
import {
  Button,
  SpaceBetween,
  StatusIndicator,
} from "@amzn/awsui-components-react-v3/polaris";
import { Alert } from "@amzn/awsui-components-react";
import "../../res/css/FileSelector.css";
import { WisApiImp } from "../ts/api/WisApi";
import { AppContext } from "../ts/util/AppContext";
import { Status } from "../ts/Status";

/**
 * Batch Uploading Tab under What's in Style main Page
 */
export class WISBatchUploading extends React.Component<any, any> {
  private fileName: string;
  private fileContent: string;

  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  constructor(prop, state) {
    super(prop, state);
    this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
    this.state = {
      isFileSelected: false,
      isFileFormatValid: false,
      fileUploadState: Status.BeforeLoading,
      responseCode: null,
    };
  }

  /**
   * Force current component to re-render
   */
  forceUpdateHandler() {
    this.forceUpdate();
  }

  /**
   * Reset component to beginning state, call forceUpdateHandler to re-render
   */
  resetState = async () => {
    await this.setState({
      isFileSelected: false,
      isFileFormatValid: false,
      fileUploadState: Status.BeforeLoading,
    });
    await this.forceUpdateHandler();
  };

  /**
   * Get fileName and fileContent from FileSelector
   * Do basic file format check: only allow user to upload .txt file.
   */
  fetchFileNameAndContent = async (e) => {
    const selectedFileName = e.target.files[0].name;
    this.setState({ isFileSelected: true });
    this.fileName = selectedFileName;

    try {
      if (selectedFileName.endsWith(".txt")) {
        const reader = new FileReader();
        reader.onload = async (e) => {
          this.fileContent = e.target.result.toString();
        };
        reader.readAsText(e.target.files[0]);
        this.fileContent = e.target.files[0];
        this.setState({ isFileFormatValid: true });
      } else {
        this.setState({ isFileFormatValid: false });
        this.forceUpdateHandler();
      }
    } catch (e) {
      console.error(`Error During get Content from File : + ${e}`);
      this.setState({ isFileFormatValid: false });
      this.forceUpdateHandler();
    }
  };

  /**
   * Send /batch Rest Api call to backend, pass fileName and fileContent in request Body
   */
  uploadFileAfterConfirm = async () => {
    this.setState({ fileUploadState: Status.Loading });
    let res;
    try {
      console.log("User Name " + (await this.context.getUserAmazonAlias()));
      res = await new WisApiImp().batchUpload(this.fileName, this.fileContent);
      console.log(res);
    } catch (e) {
      await this.setState({ responseCode: 500 });
      this.forceUpdateHandler();
      return;
    }

    if (res.status === 200) {
      this.setState({ fileUploadState: Status.Loaded });
    } else {
      this.setState({
        fileUploadState: Status.LoadingFailed,
        responseCode: res.status,
      });
    }
    this.setState({ isFileSelected: false });
    this.forceUpdateHandler();
  };

  /**
   * Show file upload status after user click confirm upload
   */
  showResponseStatus = (state) => {
    switch (state.fileUploadState) {
      case Status.Loading:
        return (
          <StatusIndicator type="loading">
            Loading Data to S3....
          </StatusIndicator>
        );
      case Status.Loaded:
        return (
          <StatusIndicator type="success">
            Successfully loaded File to S3
          </StatusIndicator>
        );
      case Status.LoadingFailed:
        return (
          <StatusIndicator type="error">
            Error in Response: {state.responseCode}
          </StatusIndicator>
        );
      default:
        return undefined;
    }
  };

  render() {
    return (
      <div>
        <SpaceBetween size="l">
          <Alert header="Click the Below Button to Upload data to S3 Bucket">
            Click Button to select a .txt file, upload it to S3 Bucket,{"\n"}
            Our Lambda function will Process the data and load them into
            DynamoDB,{"\n"}
            You will get Email notification shortly{"\n"}
          </Alert>
          <input
            title={" Click to Upload File to S3"}
            type="file"
            className="custom-file-input"
            onChange={(e) => {
              this.fetchFileNameAndContent(e);
            }}
          />

          {this.state.isFileSelected === true &&
            this.state.isFileFormatValid === false && (
              <Alert
                header="File Format Error"
                dismissible={true}
                dismissLabel="Close alert"
                onDismiss={this.resetState}
                type="warning"
              >
                Please Select a file with .txt Suffix
              </Alert>
            )}

          {this.state.isFileSelected === true &&
            this.state.isFileFormatValid === true && (
              <Alert
                header="Do you confirm your operation?"
                dismissible={true}
                dismissLabel="Close alert"
                onDismiss={this.resetState}
                type="warning"
              >
                <SpaceBetween direction="horizontal" size="xs">
                  <div className="awsui-util-d-ib">
                    The Operation Could not be undone!
                  </div>
                  <Button
                    variant="primary"
                    onClick={(e) => this.uploadFileAfterConfirm()}
                  >
                    Upload
                  </Button>
                </SpaceBetween>
              </Alert>
            )}

          {this.state.fileUploadState != Status.BeforeLoading && (
            <Alert
              dismissible={true}
              dismissLabel="Close alert"
              onDismiss={this.resetState}
            >
              {this.showResponseStatus(this.state)}
            </Alert>
          )}
        </SpaceBetween>
      </div>
    );
  }
}
