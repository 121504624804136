import * as React from "react";
import {
  Container,
  Header,
  SpaceBetween
} from "@amzn/awsui-components-react-v3/polaris";
import ReactTable from "react-table";
import "react-table/react-table.css";

export default function MetricsViewer(props) {
  return (
    <Container
      className="metrics-viewer"
      header={
        <Header variant="h2" description="View selected widget metric">
          Metrics Viewer
        </Header>
      }
    >
      <SpaceBetween size="m">
          <ReactTable
            data={props.data}
            columns={props.columns}
            defaultPageSize={10}
            className="-striped -highlight"
          />
      </SpaceBetween>
    </Container>
  );
};