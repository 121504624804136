import * as React from "react";
import { SideNavigation } from "@amzn/awsui-components-react";
import { wis } from "../ts/ProjectConfig";
import { SideNavigationProps } from "@amzn/awsui-components-react-v3";

const navHeader = { text: "Widget Data Manager Hub", href: "#/" };
const marketplaceItem: SideNavigationProps.Item[] = [];
const navItems: SideNavigationProps.Item[] = [
  {
    type: "section",
    text: wis.name,
    items: marketplaceItem,
  },
  {
    type: "divider",
  },
];

wis.marketplaces.forEach((mp) => {
  marketplaceItem.push({
    type: "link",
    text: mp.name,
    href: `${wis.mainPage}/${mp.name}`,
  });
});

export function Navigation() {
  return <SideNavigation header={navHeader} items={navItems} />;
}
