import * as React from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Container,
  DatePicker,
  FormField,
  Header,
  Select,
  SpaceBetween,
  Input
} from "@amzn/awsui-components-react-v3/polaris";
import {
  METRIC_OPTIONS,
  REGION_OPTIONS,
  ONBOARD_PAGE_PATH,
  ALL_COHORT_OPTIONS,
  DEFAULT_COHORT_OPTION,
  REGION_MARKETPLACE_WITH_COHORT_METRICS_AVAILABLE
} from "../../WinsConstant";

export default function QueryController(props) {
  const [startDate, setStartDate] = React.useState('');
  const [endDate, setEndDate] = React.useState('');
  const [regionCode, setRegionCode] = React.useState(null);
  const [marketplaceId, setMarketplaceId] = React.useState(null);
  const [widgetGroupId, setWidgetGroupId] = React.useState(null);
  const [strategyId, setStrategyId] = React.useState(null);
  const [widgetId, setWidgetId] = React.useState(null);
  const [cohortType, setCohortType] = React.useState(null);
  const [cohortOptions, setCohortOptions] = React.useState(DEFAULT_COHORT_OPTION);
  const [metric, setMetric] = React.useState(null);

  /**
  * Restricts dates in DatePicker
  * Ensures selected date is is in the past AND
  * is 4 days prior to current date due to CARL dataset delay
  */
  const dateRestriction = (date) => {
    const currentTime = Date.now();
    const timeDiff = Math.abs(currentTime - date.getTime());
    const millisecondsPerDay = 1000 * 60 * 60 * 24;
    const dayDiff = Math.ceil(timeDiff / millisecondsPerDay);
    return dayDiff > 4 && date.getTime() < currentTime;
  }

  const setCohortOptionsList = (selectedRegionCode = regionCode, selectedMarketplaceId = marketplaceId) => {
    if (selectedRegionCode &&
      selectedMarketplaceId &&
      REGION_MARKETPLACE_WITH_COHORT_METRICS_AVAILABLE.has([selectedRegionCode.value, selectedMarketplaceId].join(":"))) {
      setCohortOptions(ALL_COHORT_OPTIONS);
    } else {
      setCohortOptions(DEFAULT_COHORT_OPTION);
    }
  }

  const history = useHistory();

  return(
    <Container
      header={
        <Header
          variant="h2"
          description="Onboard widget first before querying metrics"
          actions={
            <Button variant="primary"
                    onClick={() => history.push(ONBOARD_PAGE_PATH)}>
                    Onboard
            </Button>
          }
        >
          Query Controller
        </Header>
      }
    >
      <SpaceBetween size="l">
        <FormField label="Start Date">
          <DatePicker
            placeholder="YYYY/MM/DD"
            value={startDate}
            onChange={event => setStartDate(event.detail.value)}
            isDateEnabled={dateRestriction}
          />
        </FormField>
        <FormField
          label="End Date"
          constraintText="Date range querying currently not available. Select any End Date after Start Date.">
          <DatePicker
            placeholder="YYYY/MM/DD"
            value={endDate}
            onChange={event => setEndDate(event.detail.value)}
            isDateEnabled={dateRestriction}
          />
        </FormField>
        <FormField label="Region Code">
          <Select
              selectedOption={regionCode}
              onChange={({detail}) => {
                setRegionCode(detail.selectedOption);
                setCohortOptionsList(detail.selectedOption, undefined);
              }}
              options={ REGION_OPTIONS }
              placeholder="Choose a region"
          />
        </FormField>
        <FormField label="Marketplace Id">
          <Input
              onChange={({detail}) => {
                setMarketplaceId(detail.value);
                setCohortOptionsList(undefined, detail.value);
              }}
              value={marketplaceId}
              placeholder="Enter marketplace id"
          />
        </FormField>
        <FormField label="Widget Group Id">
          <Input
              onChange={({ detail }) => setWidgetGroupId(detail.value)}
              value={widgetGroupId}
              placeholder="Enter widget group id"
          />
        </FormField>
        <FormField label="Strategy Id">
          <Input
              onChange={({ detail }) => setStrategyId(detail.value)}
              value={strategyId}
              placeholder="Enter strategy id"
          />
        </FormField>
        <FormField label="Widget Id">
          <Input
              onChange={({ detail }) => setWidgetId(detail.value)}
              value={widgetId}
              placeholder="Enter widget id"
          />
        </FormField>
        <FormField
          label="Cohort Type"
          constraintText="Different cohort data currently only available for eu region/44571 marketplace">
          <Select
            selectedOption={cohortType}
            onChange={({detail}) => setCohortType(detail.selectedOption)}
            options={ cohortOptions }
            placeholder="Choose a cohort type"
          />
        </FormField>
        <FormField label="Metric">
          <Select
            selectedOption={metric}
            onChange={({detail}) => setMetric(detail.selectedOption)}
            options={ METRIC_OPTIONS }
            placeholder="Choose a metric"
          />
        </FormField>
        {
          props.loading ?
            <Button loading variant="primary">
              Loading Metric
            </Button>
            :
            <Button
              variant="primary"
              onClick={() => props.onSubmitQuery(
                startDate,
                endDate,
                regionCode,
                marketplaceId,
                widgetGroupId,
                strategyId,
                widgetId,
                cohortType,
                metric
              )}
            >
              Submit
            </Button>
        }
      </SpaceBetween>
    </Container>
  );
}