import React = require("react");
import { AppLayout } from "@amzn/awsui-components-react";
import { MainPage } from "./MainPage";
import { WISMainPage } from "./WhatsInStyleMainPage";
import { CEWMainPage } from "./ColorExtractionWorkflowMainPage";
import WinsMainPage from "./WidgetInstrumentationMainPage";
import { HashRouter, Route } from "react-router-dom";
import { Hub } from "@aws-amplify/core";
import { Navigation } from "../commons/Navigation";
import { Builder } from "builder-pattern";
import { ClientConfig } from "../ts/configuration/ClientConfig";
import { AppContext } from "../ts/util/AppContext";
import { Status } from "../ts/Status";

/**
 * Different Pages for WebApp
 */
const routes = [
  {
    path: "/",
    content: (matchprops) => <MainPage />,
    navigation: <Navigation />,
    contentType: "default",
    maxContentWidth: 3000,
  },
  {
    path: "/whatsInStyle/*",
    content: (matchprops) => <WISMainPage />,
    navigation: <Navigation />,
    contentType: "default",
    maxContentWidth: 3000,
  },
  {
    path: "/beautyConsole/*",
    content: (matchprops) => <CEWMainPage />,
    navigation: <Navigation />,
    contentType: "default",
    maxContentWidth: 3000,
  },
  {
    path: "/widgetInstrumentation/*",
    content: (matchprops) => <Route path='/widgetInstrumentation' component={WinsMainPage}></Route>,
    navigation: <Navigation />,
    contentType: "default",
    maxContentWidth: 3000,
  },
];

interface AppProps {}

class AppState {
  status: Status;
  config: ClientConfig;
  navigationExpanded: boolean;

  constructor() {
    this.status = Status.Loading;
    this.config = null;
    this.navigationExpanded = false;
  }

  public static loaded(state: AppState, config: ClientConfig): AppState {
    return Builder(state).status(Status.Loaded).config(config).build();
  }

  public static loadingFailed(state: AppState): AppState {
    return Builder(state).status(Status.LoadingFailed).build();
  }

  public static toggleNavigation(appPage: AppPage, expanded: boolean) {
    appPage.setState(
      Builder(appPage.state).navigationExpanded(expanded).build()
    );
  }
}

export default class AppPage extends React.Component<AppProps, AppState> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  constructor(props: AppProps) {
    super(props);
    Hub.listen("auth", (data) => {
      switch (data.payload.event) {
        case "signIn":
          console.log("receive signIn event");
          this.onSignedIn();
          break;
        case "signIn_failure":
          console.log(
            `receive signIn failed event with message ${data.payload.message}`
          );
          this.signInFailed(data.payload.message);
          break;
        default:
          break;
      }
    });
    this.state = new AppState();
  }

  async onLoaded() {
    this.setState(AppState.loaded(this.state, await this.context.getConfig()));
  }

  /**
   * Receive SignIn event. Attempting to get user Identity.
   * If user is not signed in, attempting to redirect the user to sign in.
   */
  async onSignedIn() {
    try {
      await this.context.getIdentity();
      await this.onLoaded();
    } catch { // Redirect user to sign in
        await this.context.signIn();
      return;
    }
  }

  async signInFailed(message: string) {
    console.log("Sign in failure " + message);
    this.setState(AppState.loadingFailed(this.state));
  }

  async componentDidMount() {
    await this.context.init();
    console.log("Component did mount is called...");
    await this.onSignedIn();
  }

  onNavigationToggle(expanded: boolean) {
    AppState.toggleNavigation(this, expanded);
  }

  content = () => {
    return routes.map((r) => {
      return (
        <Route
          exact
          path={r.path}
          render={(matchProps) => {
            return (
              <div className="awsui">
                <AppLayout
                  contentType={r.contentType as AppLayout.ContentType}
                  navigation={<Navigation />}
                  content={r.content(matchProps)}
                  maxContentWidth={r.maxContentWidth}
                  navigationOpen={this.state.navigationExpanded}
                  onNavigationChange={(event) =>
                    this.onNavigationToggle(event.detail.open)
                  }
                  toolsHide={true}
                />
              </div>
            );
          }}
        />
      );
    });
  };

  render() {
    return (
      <div>
        <HashRouter>{this.content()}</HashRouter>
      </div>
    );
  }
}
