import * as React from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Container,
  FormField,
  Header,
  SpaceBetween,
  Input,
  Select,
  Flashbar
} from "@amzn/awsui-components-react-v3/polaris";
import {WINS_API_PATH_ONBOARD, REGION_OPTIONS} from "../../WinsConstant";
import {MidwayIdentityClientFactory} from "../../ts/factory/MidwayIdentityClientFactory";

export default function OnboardingController(props) {
  const [regionCode, setRegionCode] = React.useState(null);
  const [marketplaceId, setMarketplaceId] = React.useState(null);
  const [widgetGroupId, setWidgetGroupId] = React.useState(null);
  const [strategyId, setStrategyId] = React.useState(null);
  const [widgetId, setWidgetId] = React.useState(null);
  const [CTI, setCTI] = React.useState(null);

  const [onboardLoading, setOnboardLoading] = React.useState(false);

  const [flashMessage, setFlashMessage] = React.useState([]);
  const successFlashMessage = {
    type: "success",
    content: "successful onboarding",
    action: <Button onClick={() => history.goBack()}>Main Page</Button>,
    dismissible: true,
    dismissLabel: "Dismiss message",
    onDismiss: () => setFlashMessage([])
  };
  let errorFlashMessage = {
    header: "Onboarding Error",
    type: "error",
    content: "Error occurred",
    dismissible: true,
    dismissLabel: "Dismiss message",
    onDismiss: () => setFlashMessage([])
  };

  const history = useHistory();

  const validateCTI = (cti) => {
    const ctiComponents = cti.split("/");
    for (let str of ctiComponents) {
      if (str.length === 0 || !str.trim()) { // Ensure components aren't empty
        return false;
      }
    }
    return ctiComponents.length === 3;
  }

  const submitOnboardRequest = async (regionCode, marketplaceId, widgetGroupId, strategyId, widgetId, CTI) => {

    if (!regionCode || !marketplaceId || !widgetGroupId || !strategyId || !widgetId || !CTI) {
      alert("Missing Fields");
      return;
    }

    // Ensure CTI input contains all 3 CTI components
    if (!validateCTI(CTI)) {
      alert("CTI must be formatted as 'C / T / I'");
      return;
    }

    const client = await MidwayIdentityClientFactory.getClient();
    const payload = {
      regionCode: regionCode.value,
      marketplaceId: marketplaceId,
      widgetId: widgetId,
      widgetGroupId: widgetGroupId,
      strategyId: strategyId,
      CTI: CTI
    };
    setOnboardLoading(true);

    await client.post(WINS_API_PATH_ONBOARD, payload).then((response: any) => {
      successFlashMessage["content"] = response.data.message;
      setFlashMessage([successFlashMessage]);
      setOnboardLoading(false);
    }, (error) => {
      errorFlashMessage["content"] = error.response.data["Error"];
      setFlashMessage([errorFlashMessage]);
      setOnboardLoading(false);
    });

  };

  return (
    <div>
      <Flashbar items={flashMessage}></Flashbar>
      <Container
        header={
          <Header
            variant="h2"
            description="Onboard a widget to generate daily metrics"
            actions={
              <Button onClick={() => history.goBack()}>Back</Button>
            }
          >
            Onboard Widget
          </Header>
        }
      >
        <SpaceBetween size="l">
          <FormField label="Region Code">
            <Select
              selectedOption={regionCode}
              onChange={({detail}) => setRegionCode(detail.selectedOption)}
              options={ REGION_OPTIONS }
              placeholder="Choose a region"
            />
          </FormField>
          <FormField label="Marketplace Id">
            <Input
              onChange={({ detail }) => setMarketplaceId(detail.value)}
              value={marketplaceId}
              placeholder="Enter marketplace id"
            />
          </FormField>
          <FormField label="Widget Group Id">
            <Input
              onChange={({ detail }) => setWidgetGroupId(detail.value)}
              value={widgetGroupId}
              placeholder="Enter widget group id"
            />
          </FormField>
          <FormField label="Strategy Id">
            <Input
              onChange={({ detail }) => setStrategyId(detail.value)}
              value={strategyId}
              placeholder="Enter strategy id"
            />
          </FormField>
          <FormField label="Widget Id">
            <Input
              onChange={({ detail }) => setWidgetId(detail.value)}
              value={widgetId}
              placeholder="Enter widget id"
            />
          </FormField>
          <FormField label="CTI (Category / Type / Item)">
            <Input
              onChange={({ detail }) => setCTI(detail.value)}
              value={CTI}
              placeholder="Enter CTI"
            />
          </FormField>
          {
            onboardLoading ?
              <Button loading variant="primary">
                Onboarding...
              </Button>
              :
              <Button
                variant="primary"
                onClick={() => submitOnboardRequest(
                  regionCode,
                  marketplaceId,
                  widgetGroupId,
                  strategyId,
                  widgetId,
                  CTI
                )}
              >
                Onboard
              </Button>
          }
        </SpaceBetween>
      </Container>
    </div>
  );
}