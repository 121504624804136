import {
  ClientConfig,
  ClientConfigFactory,
} from "../configuration/ClientConfig";
import { CognitoUser } from "@aws-amplify/auth";
import Auth from "@aws-amplify/auth";
import { AmplifyFactory } from "../factory/AmplifyFactory";
import { WisApi, WisApiImp } from "../api/WisApi";
import { Builder } from "builder-pattern";
import { Identity, IdentityType } from "@amzn/domain-driven-design";

export interface AppContextValue {
  init(): Promise<void>;
  signIn(): Promise<void>;
  signOut(): Promise<void>;
  getCurrentUser(): Promise<CognitoUser>;
  getUserAmazonAlias(): Promise<string>;
  getIdentity(): Promise<any>;
  getConfig(): Promise<ClientConfig>;
}

export class AppContextValueImp implements AppContextValue {
  config: ClientConfig;
  wisApi: WisApi;

  async init(): Promise<void> {
    this.config = await this.getConfig();
    AmplifyFactory.createAuth(this.config);
    AmplifyFactory.createRestAPI(this.config);
    this.wisApi = new WisApiImp();
  }

  async signIn(): Promise<void> {
    await Auth.federatedSignIn({
      customProvider: "AmazonFederateOIDC",
    });
  }

  async signOut(): Promise<void> {
    await Auth.signOut();
    await this.signIn();
  }

  async getCurrentUser(): Promise<CognitoUser> {
    return await Auth.currentAuthenticatedUser();
  }

  async getIdentity(): Promise<any> {
    return Builder<Identity>()
      .type(IdentityType.Person)
      .id(
        (await this.getCurrentUser())
          .getSignInUserSession()
          .getIdToken()
          .decodePayload()["identities"][0]["userId"]
      )
      .build();
  }

  async getUserAmazonAlias(): Promise<string> {
    return (await this.getCurrentUser())
      .getSignInUserSession()
      .getIdToken()
      .decodePayload()["identities"][0]["userId"];
  }

  async getConfig(): Promise<ClientConfig> {
    return await ClientConfigFactory.getConfig();
  }
}