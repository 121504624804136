/**
 * TrendCollection Object for What's In Style
 */
export class TrendCollection {
  trendCollectionId: number;
  subCategoryId: number;
  subCategory: string;
  landingPageUrl: string;
  trendCallout: string;
  subHeader?: string;
  curatedTime: string;
  timeToLive: number;
  expirationTime: string;
  curatedPersonAlias: string;
  marketplaceId: string;
  asinsForShovelers: string[];
  keywordsSet: string[];
  imageUrl: string;

  constructor(rawData) {
    this.trendCollectionId = rawData.trendCollectionId;
    this.subCategoryId = rawData.subCategoryId;
    this.subCategory = rawData.subCategory;
    this.landingPageUrl = "https://www.amazon.in" + rawData.landingPageUrl;
    this.trendCallout = rawData.trendCallout;
    this.subHeader = rawData.subHeader;
    this.curatedTime =
      rawData.curatedTime.dateTime.date.month +
      "/" +
      rawData.curatedTime.dateTime.date.day +
      "/" +
      rawData.curatedTime.dateTime.date.year;
    this.timeToLive = rawData.timeToLive;
    this.expirationTime =
      rawData.expirationTime.dateTime.date.month +
      "/" +
      rawData.expirationTime.dateTime.date.day +
      "/" +
      rawData.expirationTime.dateTime.date.year;
    this.marketplaceId = rawData.marketplaceId;
    this.asinsForShovelers = rawData.asinsForShovelers;
    this.keywordsSet = rawData.keywordsSet;
    this.imageUrl = rawData.imageUrl;
    this.curatedPersonAlias = rawData.curatedPersonAlias;
  }
}
