import { RestAPI } from "@aws-amplify/api-rest";
import {
  WIS_API_PATH_BATCH,
  WIS_API_PATH_CREATE,
  WIS_API_PATH_DELETE,
  WIS_API_PATH_LIST,
  WIS_REST_API,
} from "../../Constant";
import { TrendCollection } from "../TrendCollection";

export interface WisApi {
  listCollections(input?: string);
  deleteCollection(input: TrendCollection);
  createCollection(input: TrendCollection);
  batchUpload(fileName: string, fileContent: string);
}

export class WisApiImp implements WisApi {
  apiName = WIS_REST_API;

  /**
   * Send list REST api request to APIGateway via amplify
   * Return all TrendCollections data in dynamoDB
   */
  async listCollections() {
    const path = WIS_API_PATH_LIST;
    const request = {
      headers: {},
      response: true,
    };
    return RestAPI.get(this.apiName, path, request)
      .then((response) => {
        return response.data.Message;
      })
      .catch((error) => {
        console.error("Error from list : " + error);
        return error;
      });
  }

  /**
   * Send batch REST api request to APIGateway via amplify
   * Will put local file into target S3 bucket
   */
  async batchUpload(fileName: string, fileContent: string) {
    const path = WIS_API_PATH_BATCH;
    const request = {
      headers: {},
      body: {
        FileName: fileName,
        FileContent: fileContent,
      },
      response: true,
    };
    return RestAPI.post(this.apiName, path, request)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error("Error from batchUpload : " + error);
        return error;
      });
  }

  /**
   * Send delete REST api request to APIGateway via amplify
   * Will delete the target Collection from DynamoDB
   */
  async deleteCollection(trendCollection: TrendCollection) {
    const path = WIS_API_PATH_DELETE;
    const request = {
      headers: {},
      body: {
        TrendCollection: trendCollection,
      },
      response: true,
    };
    return RestAPI.del(this.apiName, path, request)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error("Error from delete Collection : " + error);
        return error;
      });
  }

  /**
   * Send create REST api request to APIGateway via amplify
   * Will create the target Collection in DynamoDB
   */
  async createCollection(trendCollection: TrendCollection) {
    trendCollection.trendCollectionId = 1111;
    const path = WIS_API_PATH_CREATE;
    const request = {
      headers: {},
      body: {
        TrendCollection: trendCollection,
      },
      response: true,
    };
    return RestAPI.put(this.apiName, path, request)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error("Error from create Collection : " + error);
        return error;
      });
  }
}
