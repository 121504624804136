import * as React from "react";
import {
  Switch,
  Route
} from "react-router-dom";
import {
  Grid,
  Header,
  SpaceBetween,
} from "@amzn/awsui-components-react-v3/polaris";
import QueryController from "../component/widget-instrumentation/query-controller";
import OnboardingController from "../component/widget-instrumentation/onboarding-controller";
import MetricsViewer from "../component/widget-instrumentation/metrics-viewer";
import { MidwayIdentityClientFactory } from "../ts/factory/MidwayIdentityClientFactory";
import { DEFAULT_COLUMNS, DEFAULT_DATA, WINS_API_PATH_METRIC_QUERY, ONBOARD_PAGE_PATH, QUERY_PAGE_PATH } from "../WinsConstant";

function Content(props) {
  return (
    <SpaceBetween size="m">
      <Header
        variant="h1"
        description="Get insight of ASIN-level metrics for widget owner"
      >
        Widget Instrumentation
      </Header>
      <Grid
        gridDefinition={[
          {colspan: {l: 4, m: 4, default: 12}},
          {colspan: {l: 8, m: 8, default: 12}}
        ]}
      >
        <QueryController onSubmitQuery={props.onSubmitQuery} loading={props.loading}/>
        <MetricsViewer data={props.data} columns={props.columns}/>
      </Grid>
    </SpaceBetween>
  );
}

export default function WinsMainPage() {
  const [columns, setColumns] = React.useState(DEFAULT_COLUMNS);
  const [data, setData] = React.useState(DEFAULT_DATA);
  const [loading, setLoading] = React.useState(false);

  /**
   * Transform the column string to be acceptable by the React Table component
   * i.e, given columnString "c1|c2" and columnDelimiter "|",
   * this will transform it into
   * [
   *   {Header: c1, accessor: c1},
   *   {Header: c2, accessor: c2}
   * ]
   * @param columnString: String
   * @param columnDelimiter: String
   */
  const makeColumns = (columnString, columnDelimiter) => {
    return columnString.split(columnDelimiter).map(column => {
      return {Header: column, accessor: column};
    });
  }

  /**
   * Transform the data string to be acceptable by the React Table component
   * i.e, given columnString "c1|c2", dataString "d1|d2~d3|d4", rowSeparator "~" and columnDelimiter "|",
   * this function will transform it into
   * [
   *   {c1: d1, c2: d2},
   *   {c1: d3, c2: d4}
   * ]
   * @param columnString: String
   * @param dataString: String
   * @param rowSeparator: String
   * @param columnDelimiter: String
   */
  const makeData = (columnString, dataString, rowSeparator, columnDelimiter) => {
    return dataString.split(rowSeparator)
      .map(rowString => rowString.split(columnDelimiter))
      .map(row => columnString.split(columnDelimiter)
        .reduce((obj, key, index) => ({...obj, [key]: row[index]}), {}));
  }

  const onSubmitQuery = async (startDate, endDate, regionCode, marketplaceId, widgetGroupId, strategyId, widgetId, cohortType, metric) => {
    if ((startDate && endDate) && (startDate > endDate)) {
      alert("Date range unavailable");
      return;
    } else if (!startDate || !regionCode || !marketplaceId || !widgetGroupId || !strategyId || !widgetId || !cohortType || !metric) {
      alert("Missing Fields");
      return;
    }

    const client = await MidwayIdentityClientFactory.getClient();
    const payload = {
      date: startDate,
      regionCode: regionCode.value,
      marketplaceId: marketplaceId,
      widgetGroupId: widgetGroupId,
      strategyId: strategyId,
      widgetId: widgetId,
      cohortType: cohortType.value,
      metric: metric.value
    }
    setLoading(true);

    await client.post(WINS_API_PATH_METRIC_QUERY, payload).then((response: any) => {
      if (response.data.data != "null") {
        setColumns(makeColumns(response.data.header, response.data.columnDelimiter));
        setData(makeData(response.data.header, response.data.data, response.data.rowSeparator, response.data.columnDelimiter));
      } else {
        setColumns(DEFAULT_COLUMNS);
        setData(DEFAULT_DATA);
      }
      setLoading(false);
    }, (error) => {
      setColumns(DEFAULT_COLUMNS);
      setData(DEFAULT_DATA);
      setLoading(false);
    });
  }

  const renderWinsMainPage = () => {
    return (
        <div className="awsui">
          <Content onSubmitQuery={onSubmitQuery} data={data} columns={columns} loading={loading}/>
        </div>
    );
  }

  return (
      <Switch>
        <Route exact path={QUERY_PAGE_PATH}>{renderWinsMainPage()}</Route>
        <Route exact path={ONBOARD_PAGE_PATH} component={OnboardingController}></Route>
      </Switch>
  );
};