import * as React from "react";
import { Button, Cards } from "@amzn/awsui-components-react";
import { projects, ProjectStatus } from "../ts/ProjectConfig";
import { StatusIndicator } from "@amzn/awsui-components-react-v3/uxdg";

export class MainPage extends React.Component<any, any> {
  constructor(props) {
    super(props);
  }

  render() {
    return <Content />;
  }
}

const cardDefinition = {
  header: (project) => (
    <h2>
      <b> {project.name} </b>
    </h2>
  ),
  sections: [
    {
      header: <b> Description </b>,
      content: (project) => project.description,
    },
    {
      header: <b> Support MarketPlace </b>,
      content: (project) =>
        project.marketplaces.map((e, key) => {
          return (
            <Button variant="primary" href={`${project.mainPage}/${e.name}`}>
              {" "}
              {e.name}{" "}
            </Button>
          );
        }),
    },
    {
      header: <b> Design Wiki </b>,
      content: (project) => (
        <Button icon="external" href={project.wiki}>
          {" "}
          Tech Design Wiki{" "}
        </Button>
      ),
    },
    {
      header: <b> Metric DashBoard </b>,
      content: (project) => (
        <Button icon="external" href={project.metricDashBoard}>
          {" "}
          Metric DashBoard{" "}
        </Button>
      ),
    },
    {
      header: <b> Status </b>,
      content: (project) =>
        project.status === ProjectStatus.deployed ? (
          <StatusIndicator type="success">
            {" "}
            <b>{project.status}</b>{" "}
          </StatusIndicator>
        ) : (
          <StatusIndicator type="warning">
            {" "}
            <b>{project.status}</b>{" "}
          </StatusIndicator>
        ),
    },
  ],
};

const cardsPerRow = [
  {
    minWidth: 800,
    cards: 2,
  },
];

export function Content() {
  return (
    <Cards
      cardDefinition={cardDefinition}
      items={projects}
      cardsPerRow={cardsPerRow}
      loadingText="Loading resources"
      header={<h2> IN Softlines Widget Data Manager </h2>}
    />
  );
}
