import * as React from "react";
import {
  Button,
  Header,
  StatusIndicator,
} from "@amzn/awsui-components-react-v3/polaris";
import { Modal } from "@amzn/awsui-components-react";
import { WisApiImp } from "../ts/api/WisApi";
import { Status } from "../ts/Status";
import { SpaceBetween } from "@amzn/awsui-components-react-v3/uxdg";

interface CollectionDeleteConfirmModalState {
  deleteState: Status;
  failedReason: string;
}

export class CollectionDeleteConfirmModal extends React.Component<
  any,
  CollectionDeleteConfirmModalState
> {
  constructor(prop, state) {
    super(prop, state);
    this.state = {
      deleteState: Status.BeforeLoading,
      failedReason: "",
    };
    this.onConfirm = this.onConfirm.bind(this);
  }

  render() {
    return (
      <Modal
        visible={true}
        expandToFit={true}
        size="medium"
        header={`Do you confirm to Delete Collection: ${this.props.trendCollectionToDelete.trendCollectionId} ? `}
        content={
          <SpaceBetween size="l" direction="vertical">
            <b>
              Selected Collection will be removed from DynamoDB and will not be
              used as data source to drive What's In Style widget on Search Page
              !
            </b>
            {this.state.deleteState !== Status.BeforeLoading &&
              this.showDeleteOperationStatus()}
          </SpaceBetween>
        }
        footer={
          <Header
            variant="h1"
            actions={
              <Button
                disabled={this.state.deleteState !== Status.BeforeLoading}
                variant="primary"
                onClick={this.onConfirm}
              >
                DELETE
              </Button>
            }
          />
        }
        onDismiss={this.props.dismissHandler && this.props.refresh}
      />
    );
  }

  /**
   * reset state to show user operation status after click DELETE button in Modal
   */
  async onConfirm() {
    this.setState({ deleteState: Status.Loading });
    let res;
    try {
      res = await new WisApiImp().deleteCollection(
        this.props.trendCollectionToDelete
      );
    } catch (e) {
      this.setState({
        deleteState: Status.LoadingFailed,
        failedReason: e.toString(),
      });
    }
    if (res.status === 200) {
      this.setState({ deleteState: Status.Loaded });
    } else {
      this.setState({
        deleteState: Status.LoadingFailed,
        failedReason: res.toString(),
      });
    }
  }

  showDeleteOperationStatus() {
    if (this.state.deleteState === Status.Loaded) {
      return (
        <StatusIndicator type="success">
          Delete Operation Success
        </StatusIndicator>
      );
    } else if (this.state.deleteState === Status.Loading) {
      return <StatusIndicator type="loading">Deleting...</StatusIndicator>;
    } else {
      return (
        <StatusIndicator type="error">
          Delete Operation Failed + {this.state.failedReason}
        </StatusIndicator>
      );
    }
  }
}
