enum Stage {
  Beta = "beta",
  Gamma = "gamma",
  Prod = "prod",
}

export interface ClientConfig {
  whatsinstyleRestApi: {
    url: string;
  };
  colorExtractionWorkflowRestApi: {
    url: string;
  };
  widgetInstrumentationRestApi: {
    url: string;
    cognitoIdentityPoolId: string;
    scheme: string;
    service: string;
  };
  cognito: {
    userPoolName: string;
    clientId: string;
    domain: string;
    signInRedirectUrl: string;
    signOutRedirectUrl: string;
    responseType: string;
    scope: string[];
  };
  stage: string;
  awsRegion: string;
}

const betaStageConfig: ClientConfig = {
  whatsinstyleRestApi: {
    url: "https://pm428dv676.execute-api.us-west-2.amazonaws.com/prod",
  },
  colorExtractionWorkflowRestApi: {
    url: "https://spo7unlv9e.execute-api.us-east-1.amazonaws.com/prod",
  },
  widgetInstrumentationRestApi: {
    url: "https://q8z4l6thvb.execute-api.us-west-2.amazonaws.com/beta",
    cognitoIdentityPoolId: "us-west-2:a74b72e1-b96f-49e6-be3c-4808ccbd63d9",
    scheme: "aws4-hmac-sha256",
    service: "execute-api",
  },
  cognito: {
    userPoolName: "us-west-2_sgJ8jrrJf",
    clientId: "53c6jei4c2aj395911d0adtl7h",
    domain: "beta-wis-data-manager.auth.us-west-2.amazoncognito.com",
    signInRedirectUrl: location.origin,
    signOutRedirectUrl: location.origin,
    scope: ["openid"],
    responseType: "code",
  },
  stage: "beta",
  awsRegion: "us-west-2",
};

const gammaStageConfig: ClientConfig = {
  whatsinstyleRestApi: {
    url: "https://lcaxgj8dgg.execute-api.eu-west-1.amazonaws.com/prod/",
  },
  colorExtractionWorkflowRestApi: {
    url: "https://qisbpz775j.execute-api.us-east-1.amazonaws.com/prod",
  },
  widgetInstrumentationRestApi: {
    url: "https://iin2ktcz1d.execute-api.eu-west-1.amazonaws.com/gamma",
    cognitoIdentityPoolId: "eu-west-1:31390137-f419-4ea9-92dc-08b318d93b41",
    scheme: "aws4-hmac-sha256",
    service: "execute-api",
  },
  cognito: {
    userPoolName: "eu-west-1_HBCfCw19G",
    clientId: "6v28hde95nipnhripq2dgnqd7c",
    domain: "gamma-wis-data-manager.auth.eu-west-1.amazoncognito.com",
    signInRedirectUrl: location.origin,
    signOutRedirectUrl: location.origin,
    scope: ["openid"],
    responseType: "code",
  },
  stage: "gamma",
  awsRegion: "eu-west-1",
};

const prodStageConfig: ClientConfig = {
  whatsinstyleRestApi: {
    url: "https://nivbzuuks7.execute-api.eu-west-1.amazonaws.com/prod/",
  },
  colorExtractionWorkflowRestApi: {
    url: "https://qisbpz775j.execute-api.us-east-1.amazonaws.com/prod"
  },
  widgetInstrumentationRestApi: {
    url: "https://hd233oy711.execute-api.eu-west-1.amazonaws.com/prod",
    cognitoIdentityPoolId: "eu-west-1:81190849-2b48-4972-889d-035dd95a79ed",
    scheme: "aws4-hmac-sha256",
    service: "execute-api",
  },
  cognito: {
    userPoolName: "eu-west-1_1BzbVA4DL",
    clientId: "5e3096gnr8firtdchvmjmsk7tm",
    domain: "prod-wis-data-manager.auth.eu-west-1.amazoncognito.com",
    signInRedirectUrl: location.origin,
    signOutRedirectUrl: location.origin,
    scope: ["openid"],
    responseType: "code",
  },
  stage: "prod",
  awsRegion: "eu-west-1",
};

export class ClientConfigFactory {
  public static async getConfig(): Promise<ClientConfig> {
    let currentConfig;
    const stage = this.getCurrentStage();
    switch (stage) {
      case Stage.Prod:
        currentConfig = prodStageConfig;
        break;
      case Stage.Gamma:
        currentConfig = gammaStageConfig;
        break;
      case Stage.Beta:
        currentConfig = betaStageConfig;
        break;
      //Dev stage: localhost:8080
      default:
        currentConfig = betaStageConfig;
        break;
    }
    return currentConfig;
  }

  static getCurrentStage() {
    const host: string = location.host;
    return host.split(".")[0];
  }
}