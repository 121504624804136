import * as React from "react";
import { TrendCollection } from "../ts/TrendCollection";
import {
  Button,
  Header,
  SpaceBetween,
} from "@amzn/awsui-components-react-v3/polaris";
import { WisApiImp } from "../ts/api/WisApi";
import { CardDefinitions, CardsPerRow } from "./ListViewCardDefinition";
import {
  Alert,
  Cards,
  CardsFiltering,
  CardsPagination,
  CardsSelection,
  Icon,
} from "@amzn/awsui-components-react";
import { Status } from "../ts/Status";
import { CollectionDeleteConfirmModal } from "./CollectionDeleteConfirmModal";

enum Action {
  view,
  create,
  update,
  delete,
}

class WISCollectionManagementTabState {
  collections: Array<TrendCollection>;
  dataStatus: Status;
  filteringText: string;
  action: Action;
  selectedCollection: TrendCollection;
}

/**
 * Collection Management Tab under What's in Style main Page
 */
export class WISCollectionManagementTab extends React.Component<
  any,
  WISCollectionManagementTabState
> {
  constructor(prop, state) {
    super(prop, state);
    this.onClearFilter = this.onClearFilter.bind(this);
    this.onFilteringChange = this.onFilteringChange.bind(this);
    this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
    this.dismissHandler = this.dismissHandler.bind(this);
    this.refresh = this.refresh.bind(this);
    this.state = {
      dataStatus: Status.Loading,
      collections: [],
      filteringText: "",
      selectedCollection: null,
      action: Action.view,
    };
  }

  /**
   * Force current component to re-render
   */
  forceUpdateHandler() {
    this.forceUpdate();
  }

  /**
   * Pass into child Component : CollectionDeleteConfirmModal to let it dismiss itself
   */
  dismissHandler() {
    this.setState({ action: Action.view });
  }

  /**
   * ComponentDidMount method are called when the current component are mount into DOM tree but before rendering
   * Send /list Rest Api call to backend to retrieve trendCollections data
   */
  async componentDidMount() {
    console.log("Component Did Mount");
    await this.retrieveCollections();
  }

  /**
   * Reset the states and retrieveCollections from REST api
   */
  async refresh() {
    this.setState({ collections: [], dataStatus: Status.Loading });
    if (this.state.action === Action.delete) {
      this.setState({ action: Action.view });
    }
    this.setState({ selectedCollection: null });
    await this.retrieveCollections();
  }

  /**
   * Call Amplify to to get Collections data from dynamoDB
   */
  async retrieveCollections() {
    const res = await new WisApiImp().listCollections();
    try {
      let trendCollections = [];
      await JSON.parse(res).forEach((item) =>
        trendCollections.push(new TrendCollection(item))
      );
      this.setState({
        collections: trendCollections,
        dataStatus: Status.Loaded,
      });
    } catch (e) {
      console.error("Error during fetch collections Details via Rest API" + e);
      this.setState({ dataStatus: Status.LoadingFailed });
    }
  }

  /**
   * Reset filteringText tobe empty
   */
  onClearFilter() {
    this.setState({ filteringText: "" });
  }

  /**
   * Reset state:filteringText with filteringText
   * which will trigger re-rendering of the collection card list view
   */
  onFilteringChange({ detail }) {
    this.setState({ filteringText: detail.filteringText });
  }

  /**
   * Show filterCounterText after applied filter
   */
  getFilterCounterText = (count) =>
    `${count} ${count === 1 ? "match" : "matches"}`;

  async onCreateButtonClick() {
    this.setState({ action: Action.create });
    let res;
    try {
      console.log(this.state.selectedCollection.trendCollectionId);
      res = await new WisApiImp().createCollection(
        this.state.selectedCollection
      );
      console.log(res);
    } catch (e) {
      console.error("Error when creating");
    }
  }

  /**
   * Get selected trendCollection Item from CardSelector
   */
  fetchSelectedCollection(event) {
    try {
      this.setState({
        selectedCollection: event.detail.selectedItems[0] as TrendCollection,
      });
    } catch (e) {
      this.setState({ selectedCollection: null });
      console.error(`Error fetchSelectedCollection ${e}`);
    }
  }

  render() {
    return (
      <SpaceBetween size="l">
        {this.state.action === Action.delete &&
          this.state.selectedCollection != null && (
            <CollectionDeleteConfirmModal
              trendCollectionToDelete={this.state.selectedCollection}
              dismissHandler={this.dismissHandler}
              refresh={this.refresh}
            />
          )}
        <Cards
          cardDefinition={CardDefinitions}
          items={this.state.collections}
          cardsPerRow={CardsPerRow}
          loading={this.state.dataStatus === Status.Loading}
          empty={
            <Alert
              header="Unable to get Collection data from DynamoDB!"
              dismissible={true}
              dismissLabel="Close alert"
              type="warning"
            >
              Please check if you have correct permission for such operation !
            </Alert>
          }
          loadingText="Fetching Data From DynamoDB"
          stickyHeader={true}
          stickyHeaderVerticalOffset={8}
          header={
            <div>
              <SpaceBetween size="xl" direction="vertical">
                <Header
                  variant="h1"
                  actions={
                    <SpaceBetween direction="horizontal" size="xs">
                      <Button
                        onClick={(e) => {
                          this.refresh();
                        }}
                      >
                        <Icon name="refresh" />
                      </Button>
                      <Button
                        disabled={this.state.selectedCollection === null}
                        onClick={(e) =>
                          this.setState({ action: Action.update })
                        }
                      >
                        Edit Selected Collection
                      </Button>
                      <Button
                        variant="primary"
                        disabled={this.state.selectedCollection === null}
                        onClick={(e) =>
                          this.setState({ action: Action.delete })
                        }
                      >
                        Remove Selected Collection
                      </Button>
                    </SpaceBetween>
                  }
                >
                  <Button
                    variant="primary"
                    onClick={(e) => {
                      this.onCreateButtonClick();
                    }}
                  >
                    {" "}
                    Create a new TrendCollection
                  </Button>
                </Header>
              </SpaceBetween>
            </div>
          }
        >
          <CardsFiltering
            filteringLabel="Filter items"
            filteringPlaceholder="Search by Any Collection Attribute"
            filteringText={this.state.filteringText}
            onFilteringChange={this.onFilteringChange}
            filteringCountTextFunction={this.getFilterCounterText}
          />
          <CardsSelection
            selectionType="single"
            keepSelection={false}
            onSelectionChange={(event) => this.fetchSelectedCollection(event)}
          />
          <CardsPagination pageSize={30} />
        </Cards>
      </SpaceBetween>
    );
  }
}
