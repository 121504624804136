import {RestAPI} from "@aws-amplify/api-rest";
import {
    CEW_REST_API,
    CEW_REST_API_BATCH_PATH,
    CEW_REST_API_CSV_PATH,
    CEW_REST_API_MARKETPLACES_PATH,
    CEW_REST_API_SETTINGS_PATH,
} from "../../CewConstant";
import ChangeSettingRequest from "../../model/ChangeSettingRequest";

/**
 * Implementation of APIs for Color Extraction Workflow console
 */
export class CewApi {
    static apiName = CEW_REST_API;

    /**
     * Calls GET /settings API to get list of settings
     * @return Promise object as API response
     */
    static async fetchSettings() {
        const path = CEW_REST_API_SETTINGS_PATH;
        const request = {
            headers: {},
            response: true,
        };
        return RestAPI.get(this.apiName, path, request);
    }

    /**
     * Calls POST /settings API to get update settings
     * @param changeSettingRequestList List containing settings to be updated for each marketplace
     * @return Promise object as API response
     */
    static async changeSettings(changeSettingRequestList: Array<ChangeSettingRequest>) {
        const path = CEW_REST_API_SETTINGS_PATH;
        const request = {
            headers: {},
            response: true,
            body: changeSettingRequestList,
        };
        return RestAPI.post(this.apiName, path, request);
    }

    /**
     * Calls GET /marketplaces API to get list of marketplaces where color extraction workflow is used
     */
    static async fetchMarketplacesList() {
        const path = CEW_REST_API_MARKETPLACES_PATH;
        const request = {
            headers: {},
            response: true,
        };
        return RestAPI.get(this.apiName, path, request);
    }

    /**
     * Calls GET /batch API to get batch run history using the filters selected by user
     * @param fromDate date from which batch run history is requested
     * @param toDate   date upto which batch run history is requested
     * @param selectedMarketplaces marketplaces for which batch run history is requested
     */
    static async fetchBatchRunList(
        fromDate: string,
        toDate: string,
        selectedMarketplaces: string[]
    ) {
        const path = CEW_REST_API_BATCH_PATH;
        const request = {
            headers: {},
            response: true,
            queryStringParameters: {
                FromDate: fromDate,
                ToDate: toDate,
                Marketplaces: JSON.stringify(selectedMarketplaces),
            },
        };
        return RestAPI.get(this.apiName, path, request);
    }

    /**
     * Calls GET /csv API to CSV stored in s3 as a pre-signed URL
     * @param selectedMarketplace marketplace related to the requested file
     * @param timestamp timestamp is used to identify the which file
     * @param filename name of the file
     */
    static async fetchCsvFile(
        selectedMarketplace: string,
        timestamp: string,
        filename: string
    ) {
        const path = CEW_REST_API_CSV_PATH;
        const request = {
            headers: {},
            response: true,
            queryStringParameters: {
                Marketplace: selectedMarketplace,
                Timestamp: timestamp,
                Filename: filename,
            },
        };
        return RestAPI.get(this.apiName, path, request);
    }
}