import * as React from "react";
import { Tabs } from "@amzn/awsui-components-react-v3";
import { Badge, Header } from "@amzn/awsui-components-react-v3/polaris";
import { WISCollectionManagementTab } from "../component/WISCollectionManagement";
import { WISBatchUploading } from "../component/WISBatchUploading";
import { AppContext } from "../ts/util/AppContext";

interface AppPageProps {}

interface AppPageState {
  operator: string;
}

export class WISMainPage extends React.Component<AppPageProps, AppPageState> {
  static contextType = AppContext;
  context!: React.ContextType<typeof AppContext>;

  constructor(prop, state) {
    super(prop, state);
    this.state = {
      operator: "",
    };
  }

  /**
   * Fetch user alias
   */
  async componentDidMount() {
    this.setState({ operator: await this.context.getUserAmazonAlias() });
  }

  render() {
    return (
      <div>
        <Header
          variant="h1"
          actions={
            <Badge color="grey">
              <h2> Welcome : {this.state.operator}</h2>
            </Badge>
          }
        >
          What's In Style
        </Header>
        <Tabs
          tabs={[
            {
              label: "Manage Trend Collections",
              id: "collectionManagementTab",
              content: <WISCollectionManagementTab />,
            },
            {
              label: "Batch Uploading",
              id: "batchUploadingTab",
              content: <WISBatchUploading />,
            },
          ]}
        />
      </div>
    );
  }
}
