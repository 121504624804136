import {
    Alert,
    Button,
    ColumnLayout,
    DatePicker,
    Link,
    Multiselect,
    SpaceBetween,
    StatusIndicator,
} from "@amzn/awsui-components-react-v3/polaris";
import {StatusCodes} from "http-status-codes";
import * as React from "react";
import {CSVLink} from "react-csv";
import "res/css/CewComponent.css";
import {
    BATCH_RUN_TAB_ALERT_HEADER,
    CLOSE_ALERT_ARIA_LABEL,
    DATE_FORMAT_FOR_BATCH_RUN,
    DATE_PLACEHOLDER,
    EXPORT_TABLE_AS_CSV_FILENAME,
    GET_BATCH_RUN_BUTTON_LABEL,
    LDAP_WITH_READ_PERMISSIONS,
    MARKETPLACE_DROPDOWN_EMPTY,
    MARKETPLACE_DROPDOWN_LOADING_TEXT,
    MARKETPLACE_DROPDOWN_PLACEHOLDER,
} from "../../CewConstant";
import {StatusIndicatorType} from "../../commons/StatusIndicatorType";
import {CewApi} from "../../ts/api/CewApi";
import {Status} from "../../ts/Status";
import {AppContext} from "../../ts/util/AppContext";
import TablePagination from "../common/TablePagination";

const dateFormat = require('dateformat');

/**
 * Batch Run Tab under ColorExtractionWorkflowMainPage
 */
export class BatchRun extends React.Component<any, any> {
    static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props) {
        super(props);
        this.state = {
            batchRunList: [],
            marketplaceList: [],
            csvList: [],
            dateRangeErrorHidden: true,
            isAuthorized: false,
            responseHidden: true,
            statusIndicatorType: "",
            responseMessage: "",
        };
        this.updateFromDate = this.updateFromDate.bind(this);
        this.updateToDate = this.updateToDate.bind(this);
        this.updateSelectedMarketplaces = this.updateSelectedMarketplaces.bind(this);
        this.fetchTableData = this.fetchTableData.bind(this);
        this.fetchMarketplaces = this.fetchMarketplaces.bind(this);
        this.downloadFile = this.downloadFile.bind(this);
        this.setStatusIndicator = this.setStatusIndicator.bind(this);
        this.handleNonOkResponse = this.handleNonOkResponse.bind(this);
        this.setCsvList = this.setCsvList.bind(this);
        this.extractUtcDateFromTimestamp = this.extractUtcDateFromTimestamp.bind(this);
    }

    /**
     * Fetches marketplaces and fills the table using default filters
     */
    async componentDidMount() {
        await this.context.init();
        await this.fetchMarketplaces();
        await this.fetchTableData();
    }

    /**
     * Sets the message and icon of status indicator based on success/error/loading event
     */
    async setStatusIndicator(state: Status, message: string) {
        switch (state) {
            case Status.Loading:
                this.setState({
                    responseHidden: false,
                    statusIndicatorType: StatusIndicatorType.Loading,
                    responseMessage: message,
                });
                break;
            case Status.Loaded:
                this.setState({
                    responseHidden: false,
                    statusIndicatorType: StatusIndicatorType.Success,
                    responseMessage: message,
                });
                break;
            case Status.LoadingFailed:
                this.setState({
                    responseHidden: false,
                    statusIndicatorType: StatusIndicatorType.Error,
                    responseMessage: message,
                });
                break;
            default:
                console.error("Invalid Status type for Status Indicator");
                break;
        }
    }

    /**
     *  Calls GET /marketplaces API to fetch marketplaces
     */
    async fetchMarketplaces() {
        await this.setStatusIndicator(Status.Loading, "Fetching Marketplaces");
        let response;
        await CewApi.fetchMarketplacesList()
            .then((res) => {
                response = res;
            })
            .catch((error) => {
                console.error("Error while fetching marketplaces : " + error);
                response = error.response;
                if (response === undefined) {
                    this.setStatusIndicator(Status.LoadingFailed, error);
                    return;
                }
            });
        switch (response.status) {
            case StatusCodes.OK:
                let marketplaceList = [];
                for (let marketplace of response.data.list) {
                    marketplaceList.push({
                        label: marketplace,
                        value: marketplace,
                    });
                }
                this.setState({marketplaceList: marketplaceList, isAuthorized: true});
                await this.setStatusIndicator(Status.Loaded, "Success");
                break;
            default:
                await this.handleNonOkResponse(response);
                break;
        }
    }

    /**
     *  Saves the user selected "from date" to Parent Component's state
     *  @param event : object containing the event information
     */
    updateFromDate(event) {
        this.props.onFromDateChange(event.detail.value);
    }

    /**
     *  Saves the user selected "to date" to Parent Component's state
     *  @param event : object containing the event information
     */
    updateToDate(event) {
        this.props.onToDateChange(event.detail.value);
    }

    /**
     *  Saves the array of selected marketplaces from multi select dropdown to Parent Component's state
     *  @param event : object containing the event information
     */
    updateSelectedMarketplaces(event) {
        this.props.onMarketplaceChange(event.detail.selectedOptions);
    }

    /**
     * Extracts date from timestamp on the basis of UTC time.
     * @param timestamp in epoch seconds
     */
    extractUtcDateFromTimestamp(timestamp) {
        return dateFormat(new Date(parseInt(timestamp) * 1000), DATE_FORMAT_FOR_BATCH_RUN, true);
    }

    /**
     *  Checks whether the date range selected by user is a valid range else shows error message below date input
     */
    isDateRangeValid() {
        const fromDate: Date = new Date(this.props.fromDate);
        const toDate: Date = new Date(this.props.toDate);
        if (fromDate > toDate) {
            this.setState({dateRangeErrorHidden: false});
            return false;
        }
        this.setState({dateRangeErrorHidden: true});
        return true;
    }

    /**
     *  Uses user provided filters and calls fetchBatchRunList API to get contents of the table.
     *  fetchBatchRunList API call is returning dummy data stored in a file.Will update this function after backend APIs are implemented
     */
    async fetchTableData() {
        const {fromDate, toDate} = this.props;
        const selectedMarketplaces = this.props.selection;
        let marketplaceArray: string[] = [];

        if (!this.isDateRangeValid()) return;
        for (let result of selectedMarketplaces) {
            marketplaceArray.push(result.value);
        }
        await this.setStatusIndicator(Status.Loading, "Fetching Batch Runs");
        let response;
        await CewApi.fetchBatchRunList(fromDate, toDate, marketplaceArray)
            .then((res) => {
                response = res;
            })
            .catch((error) => {
                console.error("Error while fetching Batch Runs : " + error);
                response = error.response;
                if (response === undefined) {
                    this.setStatusIndicator(Status.LoadingFailed, error);
                    return;
                }
            });
        switch (response.status) {
            case StatusCodes.OK:
                this.setState({batchRunList: response.data.list, isAuthorized: true});
                await this.setCsvList();
                await this.setStatusIndicator(Status.Loaded, "Success");
                break;
            default:
                await this.handleNonOkResponse(response);
                break;
        }
    }

    /**
     * Sets the csvList state using table data.This list will be passed as props to CSVLink component
     * which will allow user to download the table as an csv file.
     */
    async setCsvList() {
        let list: any[] = [];
        await this.state.batchRunList.forEach(
            (item) =>
                list.push({
                    timestamp: this.extractUtcDateFromTimestamp(item.timestamp),
                    marketplace: item.marketplace,
                    eligibleAsinsCount: item.eligibleAsinsCount,
                    mlOutputAsinsCount: item.mlOutputAsinsCount,
                    publishedAsinsCount: item.publishedAsinsCount
                })
        )
        await this.setState({csvList: list})
    }

    /**
     * Defines how data returned from API will be rendered in the table
     */
    columnDefinitions = [
        {
            id: "timestamp",
            header: "Date of Batch Run",
            cell: (item) => this.extractUtcDateFromTimestamp(item.timestamp)
        },
        {
            id: "marketplace",
            header: "Marketplace",
            cell: (item) => item.marketplace,
        },
        {
            id: "eligibleAsinsCount",
            header: "Eligible ASINs Count",
            cell: (item) =>
                this.LinkToDownload(item.eligibleAsinsCount, "eligibleAsins.csv", item),
        },
        {
            id: "mlOutputAsinsCount",
            header: "ML Output ASINs Count",
            cell: (item) =>
                this.LinkToDownload(item.mlOutputAsinsCount, "mlOutputAsins.csv", item),
        },
        {
            id: "publishedAsinsCount",
            header: "Published ASINs Count",
            cell: (item) =>
                this.LinkToDownload(item.publishedAsinsCount, "publishedAsins.csv", item),
        },
    ];

    /**
     * Creates a Link element. On clicking this link corresponding CSV file will be downloaded.
     * @param count count of ASINs corresponding to that column
     * @param filename filename to be passed as parameter to download event
     * @param item Table row item
     */
    LinkToDownload(count: String, filename: String, item) {
        if (count === undefined || count === "") {
            return <div> Not available </div>;
        }
        return (
            <Link
                variant="primary"
                onFollow={(e) =>
                    this.downloadFile(e, item.marketplace, item.timestamp, filename)
                }
            >
                {count}
            </Link>
        );
    }

    /**
     * Calls getCsv API when user clicks the link to get the S3 file and downloads the file in user browser.
     * @param event object with invoking event information
     * @param timestamp: Used by backend API to identify file
     * @param marketplace: Used by backend API to identify file
     * @param filename: Used by backend API to identify file
     */
    async downloadFile(event, marketplace, timestamp, filename) {
        await this.setStatusIndicator(Status.Loading, "Fetching CSV file");
        event.preventDefault();
        let response;
        await CewApi.fetchCsvFile(marketplace, timestamp, filename)
            .then((res) => {
                response = res;
            })
            .catch((error) => {
                console.error("Error while fetching csv: " + error);
                response = error.response;
                if (response === undefined) {
                    this.setStatusIndicator(Status.LoadingFailed, error);
                    return;
                }
            });
        switch (response.status) {
            case StatusCodes.OK:
                let url = response.data.url;
                let element = document.createElement("a");
                element.href = url;
                element.style.display = "none";
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
                await this.setStatusIndicator(Status.Loaded, "Success");
                break;
            default:
                await this.handleNonOkResponse(response);
                break;
        }
    }

    /**
     * Handles Non 200 response from API and sets the Status indicator accordingly
     * @param response
     */
    async handleNonOkResponse(response) {
        switch (response.status) {
            case StatusCodes.UNAUTHORIZED:
                await this.setState({isAuthorized: false});
                await this.setStatusIndicator(
                    Status.LoadingFailed,
                    "Unauthorized"
                );
                break;
            default:
                console.error("Received status code %i for API request", response.status);
                await this.setStatusIndicator(
                    Status.LoadingFailed,
                    response.statusText
                );
                break;
        }
    }

    render() {
        return (
            <SpaceBetween direction="vertical" size="xxs">
                <div hidden={this.state.responseHidden}>
                    <StatusIndicator type={this.state.statusIndicatorType}>
                        {this.state.responseMessage}
                    </StatusIndicator>
                </div>
                {!this.state.isAuthorized &&
                this.state.statusIndicatorType != "loading" && (
                    <Alert
                        dismissAriaLabel={CLOSE_ALERT_ARIA_LABEL}
                        header={BATCH_RUN_TAB_ALERT_HEADER}
                    >
                        Access to this section is restricted. You need to be a part of{" "}
                        {LDAP_WITH_READ_PERMISSIONS} LDAP group to access this section.
                    </Alert>
                )}
                {this.state.isAuthorized && (
                    <div>
                        <h3>Filters</h3>
                        <ColumnLayout columns={4}>
                            <div>
                                From Date
                                <DatePicker
                                    placeholder={DATE_PLACEHOLDER}
                                    value={this.props.fromDate}
                                    onChange={this.updateFromDate}
                                />
                                <div
                                    hidden={this.state.dateRangeErrorHidden}
                                    className="invalid-input-error"
                                >
                                    Enter a valid Date Range(To date should be later than From
                                    date)
                                </div>
                            </div>
                            <div>
                                To Date
                                <DatePicker
                                    placeholder={DATE_PLACEHOLDER}
                                    value={this.props.toDate}
                                    onChange={this.updateToDate}
                                />
                            </div>
                            <div>
                                Marketplaces
                                <Multiselect
                                    placeholder={MARKETPLACE_DROPDOWN_PLACEHOLDER}
                                    loadingText={MARKETPLACE_DROPDOWN_LOADING_TEXT}
                                    empty={MARKETPLACE_DROPDOWN_EMPTY}
                                    options={this.state.marketplaceList}
                                    selectedOptions={this.props.selection}
                                    onChange={this.updateSelectedMarketplaces}
                                />
                            </div>
                            <div className="get-batch-button-margin">
                                <Button variant="primary" onClick={this.fetchTableData}>
                                    {GET_BATCH_RUN_BUTTON_LABEL}
                                </Button>
                            </div>
                        </ColumnLayout>
                        <SpaceBetween direction="vertical" size="s">
                            <div className="button-float-right">
                                <Button variant="primary">
                                    <CSVLink
                                        data={this.state.csvList}
                                        filename={EXPORT_TABLE_AS_CSV_FILENAME}
                                        target="_blank"
                                        className="text-inside-button"
                                    >
                                        Export table as CSV
                                    </CSVLink>
                                </Button>
                            </div>

                            <TablePagination
                                itemList={this.state.batchRunList}
                                pageSize={10}
                                columnDefinitions={this.columnDefinitions}
                                header={"Batch Run Info"}
                            />
                        </SpaceBetween>
                    </div>
                )}
            </SpaceBetween>
        );
    }
}