export const LDAP_WITH_READ_PERMISSIONS = "Beauty-ColorExtraction-dashboard";
export const LDAP_WITH_WRITE_PERMISSIONS = "Beauty-ColorExtraction-dashboard-admin";

export const BATCH_RUN_TAB_LABEL = "Batch Runs";
export const SETTINGS_TAB_LABEL = "Settings"

export const CLOSE_ALERT_ARIA_LABEL = "Close Alert";
export const CLOSE_MODAL_ARIA_LABEL = "Close";

export const BATCH_RUN_TAB_ALERT_HEADER = "There was some problem loading Batch Run History";
export const SETTINGS_TAB_ALERT_HEADER = "There was some problem loading Model Status";

export const DATE_PLACEHOLDER = "YYYY/MM/DD";
export const MARKETPLACE_DROPDOWN_PLACEHOLDER = "Select marketplaces";
export const MARKETPLACE_DROPDOWN_LOADING_TEXT = "Loading marketplaces";
export const MARKETPLACE_DROPDOWN_EMPTY = "No marketplaces found";
export const FREQUENCY_DROPDOWN_PLACEHOLDER = "Select frequency";
export const FREQUENCY_DROPDOWN_LOADING_TEXT = "Loading frequency";
export const FREQUENCY_DROPDOWN_EMPTY = "No frequency found";

export const GET_BATCH_RUN_BUTTON_LABEL = "Get Batch Runs";
export const EXPORT_TABLE_AS_CSV_FILENAME = "batch_run_table.csv";

export const DEFAULT_DATE_RANGE_SELECTION_IN_DAYS = 30;
export const DATE_FORMAT_FOR_BATCH_RUN = "yyyy/mm/dd";

export const CEW_REST_API = "CewRestApi";
export const CEW_REST_API_SETTINGS_PATH = "/settings";
export const CEW_REST_API_BATCH_PATH = "/batch";
export const CEW_REST_API_CSV_PATH = "/csv";
export const CEW_REST_API_MARKETPLACES_PATH = "/marketplaces";

//frequency options available in drop down
export const FREQUENCY_LIST: Array<object> = [
    { label: "Monthly", value: "Monthly" },
    { label: "Fortnightly", value: "Fortnightly" },
    { label: "Weekly", value: "Weekly" },
    { label: "Daily", value: "Daily" },
];

export enum ModelStatus {
    ENABLED = "ENABLED",
    DISABLED = "DISABLED"
};