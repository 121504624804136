import {
  Badge,
  Box,
  ColumnLayout,
  Container,
  Header,
  Icon,
  Link,
  SpaceBetween,
  StatusIndicator,
} from "@amzn/awsui-components-react-v3/polaris";
import * as React from "react";

/**
 * Card Definition for TrendCollections
 */
export const CardDefinitions = {
  header: (tc) => (
    <h2>
      <Header
        variant="h2"
        actions={
          <b>
            Curated By: <Badge color="grey"> {tc.curatedPersonAlias} </Badge>
          </b>
        }
      >
        Collection ID : #{tc.trendCollectionId}
      </Header>
    </h2>
  ),
  sections: [
    {
      content: (tc) => (
        <Container>
          <ColumnLayout columns={2} variant="text-grid">
            <SpaceBetween size="l">
              <Box variant="h3" padding="n">
                Category Info
              </Box>
              <ValueWithLabel label="Sub-Category Id">
                {tc.subCategoryId.toString()}
              </ValueWithLabel>
              <ValueWithLabel label="Sub-Category ">
                {tc.subCategory}
              </ValueWithLabel>
            </SpaceBetween>
            <SpaceBetween size="l">
              <Box variant="h3" padding="n">
                Call Out Text
              </Box>
              <ValueWithLabel label="Trend-Callout">
                <Link
                  target="_blank"
                  rel="noopener noreferrer"
                  external={true}
                  href={`https://translator.amazon.com/search/strings?tag=${tc.trendCallout}&exact_match=on&text=&locale_invert=false&locale=-1&marketplace_invert=false&marketplace=-1&is_untranslated_locale_by_string=false&untranslated_locale=-1&stringset_invert=false&set=-1&team_invert=false&group=-1&query_id=&last_requested_date_type=within&last_requested_date=&last_updated_date_type=within&last_updated_date=&show_advanced=0`}
                >
                  {tc.trendCallout}
                </Link>{" "}
                <Icon name="search" size="normal" variant="subtle" />
              </ValueWithLabel>
              <ValueWithLabel label="Sub Header">{tc.subHeader}</ValueWithLabel>
            </SpaceBetween>
            <SpaceBetween size="l">
              <Box variant="h3" padding="n">
                Time Related Info
              </Box>
              <ValueWithLabel label="Curated Time">
                {tc.curatedTime}
              </ValueWithLabel>
              <ValueWithLabel label="Time-To-Live">
                {tc.timeToLive}
              </ValueWithLabel>
              <ValueWithLabel label="Current Date">
                {new Date().toLocaleDateString()}
              </ValueWithLabel>
              <ValueWithLabel label="Number of days until Expiration">
                <StatusIndicator>
                  {Math.ceil(
                    (new Date(tc.expirationTime).getTime() -
                      new Date().getTime()) /
                      (1000 * 3600 * 24)
                  )}
                </StatusIndicator>
              </ValueWithLabel>
              <ValueWithLabel label="Expiration Time">
                {tc.expirationTime}
              </ValueWithLabel>
            </SpaceBetween>
            <SpaceBetween size="l">
              <Box variant="h3" padding="n">
                Image Preview
              </Box>
              <Link
                href={tc.imageUrl}
                target="_blank"
                rel="noopener noreferrer"
                external={true}
              >
                {tc.imageUrl}
              </Link>
              <img width={210} height={280} src={tc.imageUrl} />
            </SpaceBetween>
            <SpaceBetween size="l">
              <Box variant="h3" padding="n">
                Keyword List
              </Box>
              <Box padding="n">
                {tc.keywordsSet.map((e) => {
                  return <Badge color="blue"> {e.toString()}</Badge>;
                })}
              </Box>
            </SpaceBetween>
            <SpaceBetween size="l">
              <Box variant="h3" padding="n">
                ASINs for Shovelers
              </Box>
              {tc.asinsForShovelers.map((e) => {
                return <Badge color="blue"> {e.toString()}</Badge>;
              })}
            </SpaceBetween>
          </ColumnLayout>
        </Container>
      ),
    },
    {
      header: (
        <h2>
          <b> Landing Page URL </b>
        </h2>
      ),
      content: (tc) => (
        <Link
          href={tc.landingPageUrl}
          target="_blank"
          rel="noopener noreferrer"
          external={true}
        >
          {tc.landingPageUrl}
        </Link>
      ),
    },
  ],
};
const ValueWithLabel = ({ label, children }) => (
  <div>
    <Box margin={{ bottom: "xxxs" }} color="text-label">
      {label}
    </Box>
    <div>{children}</div>
  </div>
);

export const CardsPerRow = [
  {
    minWidth: 800,
    cards: 2,
  },
];
