import {useCollection} from "@amzn/awsui-collection-hooks";
import {Header, Pagination, Table,} from "@amzn/awsui-components-react-v3/polaris";
import * as React from "react";

/**
 *Labels that will be shown in next/prev buttons
 */
const paginationLabels = {
    nextPageLabel: "Next page",
    pageLabel: (pageNumber) => `Go to page ${pageNumber}`,
    previousPageLabel: "Previous page",
};

/**
 * Functional component that takes pagination configuration, column definitions and table item list as input and returns paginated table as output
 */
export default function TablePagination(props) {
    const {items, paginationProps} = useCollection(props.itemList, {
        pagination: {pageSize: props.pageSize},
    });
    return (
        <Table
            header={<Header counter={`(${items.length})`}>{props.header}</Header>}
            columnDefinitions={props.columnDefinitions}
            items={items}
            pagination={
                <Pagination {...paginationProps} ariaLabels={paginationLabels}/>
            }
        />
    );
}