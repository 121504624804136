import { ClientConfig } from "../configuration/ClientConfig";
import RestAPI from "@aws-amplify/api-rest";
import Auth from "@aws-amplify/auth";
import {CEW_REST_API} from "../../CewConstant";
import { WIS_REST_API } from "../../Constant";

export class AmplifyFactory {
  /**
   * Configure Amplify Auth object with ClientConfig Cognito User Pool data for different stages
   */
  public static createAuth(config: ClientConfig) {
    const oauth = {
      domain: config.cognito.domain,
      scope: config.cognito.scope,
      redirectSignIn: config.cognito.signInRedirectUrl,
      redirectSignOut: config.cognito.signOutRedirectUrl,
      responseType: config.cognito.responseType,
    };
    Auth.configure({
      mandatorySignId: true,
      region: config.awsRegion,
      userPoolId: config.cognito.userPoolName,
      userPoolWebClientId: config.cognito.clientId,
      oauth,
    });
    return Auth;
  }

  /**
   * Configure Amplify RestAPI object with ClientConfig
   * Set up endpoint and req header Authorization for different stages
   */
  public static createRestAPI(config: ClientConfig) {
    RestAPI.configure({
      endpoints: [
        {
          name: WIS_REST_API,
          endpoint: config.whatsinstyleRestApi.url,
          custom_header: async () => {
            return {
              Authorization: `Bearer ${(await Auth.currentSession())
                .getIdToken()
                .getJwtToken()}`,
            };
          },
        },
        {
          name: CEW_REST_API,
          endpoint: config.colorExtractionWorkflowRestApi.url,
          custom_header: async () => {
            return {
              Authorization: `Bearer ${(await Auth.currentSession())
                  .getIdToken()
                  .getJwtToken()}`,
            };
          },
        },
      ],
    });
    return RestAPI;
  }
}
