export default class ChangeSettingRequest {
    marketplace: string;
    changeFrequencyRequired: boolean;
    frequency: string;
    toggleStatusRequired: boolean;
    toggleTo: string;

    constructor(marketplace, changeFrequencyRequired, frequency, toggleStatusRequired, toggleTo) {
        this.marketplace = marketplace;
        this.changeFrequencyRequired = changeFrequencyRequired;
        this.frequency = frequency;
        this.toggleStatusRequired = toggleStatusRequired;
        this.toggleTo = toggleTo;
    }
};
